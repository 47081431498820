.max-length {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  pointer-events: none;
  text-align: right;
  &-error {
    color: rgb(245, 102, 102);
  }
}
