.cc-table {
  font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  flex: 1;
  position: relative;
  .loading-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    display: flex;
    justify-content: center;
  }
  .loading-more-layer {
    pointer-events: none;
    background: rgba(32, 60, 94, 0.3);
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    .loading-more-text {
      color: #fff;
      margin-right: 10px;
    }
  }
  * {
    transition: unset!important;
  }
  .BaseTable__header {
    .BaseTable__header-row, .BaseTable__header-cell--sortable {
      background: #E1E5EA;
      //background-color: #E1E5EA;
      &:hover {
        background: rgba(225, 229, 234, 0.5);
        cursor: pointer;
      }
    }
    .BaseTable__header-cell-text {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
      line-height: 18px;
      color: #292B2E;

    }
    .BaseTable__sort-indicator {
      margin-left: 9px;
      display: flex!important;
      .asc-sort {
        display: none;
      }
      &:hover {
        display: none;
      }
    }
    .BaseTable__header-cell--sorting {
      .default-sort {
        display: none!important;
      }
      .asc-sort {
        display: flex;
      }
    }
  }
  .BaseTable__row {
    &.even-row {
      background: #F6F8FA;
    }
    &.odd-row {
      background: #FFFFFF;
    }
  }

  .BaseTable__row.selected-row:not(.cc-table .BaseTable__row.selected-row.selection-disabled) .icon-key-name-cell svg path {
    stroke: #F6F8FA;
  }

  .BaseTable__row:hover, .BaseTable__row--hovered {
    // background: #2F7DF6;
    &:not(&.selection-disabled) {
      background: rgba(47, 125, 246, 0.3)!important;
    }
    // For custom cellRenderer if need quick implement hover color
    .hovered {
     // color: #FFFFFF!important;
    }
  }
  .BaseTable__row.selected-row:not(.cc-table .BaseTable__row.selected-row.selection-disabled) .alarm-code svg {
    color: #F6F8FA;
  }
  .BaseTable__row.selected-row {
    &:not(&.selection-disabled) {
      background: #2F7DF6!important;
      color: #FFFFFF;
      // For custom cellRenderer if need quick implement hover color
      .hovered {
        color: #FFFFFF!important;
      }
    }
  }
  .BaseTable__row-cell {
    border-right: 1px solid #E1E5EA;
  }
}

.cc-table {
  .BaseTable {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
  }
}
.cc-column-scroll {
    background: #E1E5EA;
    position: absolute;
    z-index: 1;
    height: 32px;
    right: 0;
    padding-right: 8px;
    display: flex;
    align-items: center;

    > :first-child {
      margin-right: 12px;
    }
}
.empty-render {
  padding: 16px;
}

.deleted-record-row {
  background-color: #DBDBDB !important;
}