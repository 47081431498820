.first-citation-speed-tolerance {
  .ant-form-item-label {
    max-height: none !important;
    i {
      font-size: 11px;
    }
  }
}

.citation-legal-notes {
  min-height: 220px !important;
}