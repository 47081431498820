.citation-print {
  position: absolute;
  z-index: -99999;
  top: -3000px;
  left: -3000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.citation-print-body {
  width: 19cm;
  margin: 1cm 1cm 0 1cm;
  border: 2px solid #000000;
  font: normal normal 400 14px/18px 'Work Sans', sans-serif;

  .header {
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    .title {
      font: normal normal 500 22px/26px 'Work Sans', sans-serif;
      margin-bottom: 12px;
    }
    .title-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .info {
    display: flex;
    flex-direction: row;
    .column {
      &.violation1 {
        width: 75%;
      }
      margin: 10px;
      width: 50%;
      .field {
        margin: 10px 0;
      }
      .violation-field{
        margin-top: 3px
      }
    }
  }

  .photos {
    border: 2px solid #000000;
    margin: 10px;
    .header {
      background-color: #000000;
      &.violation {
        background-color: #E8E8E8;
        color: #000000;
        border-bottom: 2px solid #000000;
        margin-bottom: 2px;
      }
      color: #ffffff;
      text-align: center;
      padding: 4px;
    }
    .images {
      display: flex;
      gap: 15px;
      justify-content: center;
      img {
        width: 3.1cm;
      }
    }
    .sub-header {
      display: flex;
      width: 100%;
      &.violation{
        margin-top: 2px;
        background-color: #E8E8E8;
        color: #000000;
        border-bottom: 2px solid #000000;
        border-top: 2px solid #000000;
      }
      background-color: #000000;
      color: #ffffff;
      div {
        width: 100%;
        text-align: center;
        padding: 4px;
      }
    }
    .license-images {
      &.violation {
        display: flex;
        justify-content: space-around;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 190px;
          overflow: hidden;

          img {
            height: 100%;
            width: auto;
          }
        } 
        .img{
          padding: 10px;
        }
        .location{
          display: flex;
          flex-direction: column;
          div {
            width: 100%;
            margin-left: 15px;
            
          }
          a {
            text-decoration: underline;
            color: #56a7e4;
          }
        }
      }
      display: flex;
      justify-content: space-around;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 300px;
        overflow: hidden;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
  .footer {
    padding: 10px;
    div {
      margin: 12px 0;
    }
  }
  .violation-logo {
    width: 85%;
    padding: 4px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 14px auto !important;
    span {
      font-size: 10px;
    }
    img {
      width: 90px;
      height: 100%;
    }
  }
  .logos {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    img {
      width: 90px;
      height: 100%;
    }
  }
  .legal-notice {
    font-size: 10px;
    white-space: pre-line
  }
  .violation-text {
    font-size: 12px;
  }
  .orange {
    color: #ea7537;
  }
  .red {
    color: #c52534;
  }
  .green {
    color: #54a933;
  }
  .blue {
    color: #56a7e4;
  }
  .italic {
    font-style: italic;
  }
  .bold {
    font-weight: 500;
  }
}
