.guest-options {
  &-container {
    margin-left: 20px;
  }
  &-block {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
