.print {
  position: absolute;
  z-index: -99999;
  top: -3000px;
  left: -3000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.printBody {
  width: 1024px;
  font: normal normal 400 14px/18px 'Work Sans', sans-serif;

  .printContent {
    margin: 0 12px;

    div {
      margin: 12px 0;
    }

    .name {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif;
    }
  }

  .printBody {
    min-height: 25cm !important;

    .table-wrapper {
      .cc-table {
        min-height: inherit !important;
      }
    }

    .BaseTable {
      box-shadow: none;
    }

    @media print {
      .cc-column-scroll {
        display: none;
      }
    }
  }
}
