.eiq-manage {
  font: normal normal 400 14px/18px 'Work Sans';
  color: #505762;
  width: 100%;
  height: 100%;

  .ant-layout-header {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 42px;
    line-height: 42px;
    width: 100%;
    padding-left: 24px !important;
    padding-right: 24px !important;
    background-color: #ffffff;
    // border-bottom: 1px solid red;
    border-bottom: 1px solid #e1e5ea;

    .logo {
      width: 100px;
      margin-bottom: -5px;
    }

    .user-name {
      display: inline-flex;
      color: #505762;
      font: normal normal 400 12px/14px 'Work Sans';
      margin-right: 18px;
      margin-left: 18px;
    }

    .ant-btn.ant-btn-ghost {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-layout {
    min-height: 100%;
  }

  .content-style {
    padding: 20px 24px 24px 24px !important;
    display: flex;
    flex-direction: column;
    position: relative;

    .full-card {
      display: flex;
      flex: 1;
    }
  }

  .cc-menu-item {
    display: inline-flex !important;
    flex: auto;
    min-width: 0;
    align-items: center;
    padding: 0 !important;
    border-bottom: none;

    .ant-menu-item {
      display: inline-flex;
      padding: 0 !important;
      margin-top: 4px;
      height: 38px;

      &:after {
        content: unset !important;
      }
    }

    .ant-menu-title-content {
      padding: 0 10px 0 12px !important;
      border: 1px solid #ffffff;
    }

    .ant-menu-submenu-selected,
    .ant-menu-item-selected {
      .ant-menu-title-content {
        box-sizing: border-box;
        height: 40px;
        background: #f6f8fa !important;
        border-width: 1px 1px 0px 1px !important;
        border-style: solid !important;
        border-color: #e1e5ea !important;
        border-radius: 4px 4px 0px 0px !important;
      }
    }
  }

  .ant-layout-sider {
    width: 30% !important;
    min-width: 250px !important;

    &.sider-wrapper {
      background-color: #fff;

      .ant-menu-item {
        background-color: #f6f8fa;
        color: #000;
        margin-bottom: 10px;
      }

      .ant-menu-item-selected {
        background-color: #2f7df6;
        color: #fff;
      }
    }

    .sider-tabs {
      padding: 20px 10px 0 20px;
    }
  }

  .ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .app-version {
    position: absolute;
    bottom: 5px;
    left: 25px;
    z-index: 999;
    font-size: 12px;
  }
}
